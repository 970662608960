<template>
    <div class="tile is-parent is-12">
        <article class="tile is-child box">
        <p class="title">Uuden koulutuskumppanin lisääminen</p>
        <b-field label="Koulutusorganisaation nimi">
            <b-input v-model="newCompany.name"></b-input>
        </b-field>
        <b-field :class="vatFieldClass" :label="$t('message.register-company.add.company.business-id')">
            <b-input  v-model="newCompany.vat" @blur="validateVat"></b-input>
        </b-field>
        <b-field label="Kieli">
            <b-select v-model="newCompany.lang" placeholder="Valitse kieli">
                <option value="fi">SUOMI</option>
                <option value="en">ENGLANTI</option>
            </b-select>
        </b-field>
        <b-button
        :disabled="isDisabled"
        label="Lisää koulutuskumppani"
        type="is-primary"
        v-on:click="register" />
        </article>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            vatCheck: false,
            newCompany: {
                lang: "fi",
                name: "",
                vat: "",
            },
        }
    },
    computed: {
        isDisabled() {
            if(!this.vatCheck) {
                return "disabled";
            }
            return false;
        },
        vatFieldClass() {
            if(!this.vatCheck) {
                return "is-danger";
            }
            return "";
        }
    },
    methods: {
        validateVat() {
            axios.post('education-partners/validate-vat', this.newCompany).then((response) => {
                if(response.data.success) {
                    this.vatCheck = true;
                }
                else {
                    this.vatCheck = false;
                    this.toastFailure(this.$t('Y-tunnus on jo käytössä toisella koulutuskumppanilla!'));
                }
            })
        },
        register() {
            if (this.newCompany.name == "") {
                this.toastFailure(this.$t('message.error.company.name'))
                return
            } 
            if (this.newCompany.vat == "" && this.vatCheck) {
                this.toastFailure(this.$t('message.error.company.vat'))
                return
            }
            axios.post('/education-partners', this.newCompany).then((response) => {
                if(response.data.success) {
                    this.toastSuccess("Koulutuskumppani luotu onnistuneesti!");
                    this.$emit('partnerCreated',response.data.uuid);
                }
                else {
                    this.toastFailure("Koulutuskumppanin luonti epäonnistui!");
                }
            })
        }
    }
}
</script>