<template>
    <div class="container content" v-if="activeUser.type == 'admin'">
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <p class="title">Koulutuskumppanit</p>
                    <div style="float: right; margin-top:35px">
                        <b-button size="is-medium"
                            @click="openAddCompanyModal"
                            icon-left="plus">
                            Lisää uusi kumppani
                        </b-button>
                    </div>
                    <b-field label="Kumppanihaku">
                    <b-input
                        rounded
                        v-model="search"
                        placeholder="Kumppanihaku"
                        icon="magnify"
                        clearable
                        @select="option => selected = option">
                        <template #empty>No results found</template>
                    </b-input>
                </b-field>
                    <b-table
                        paginated
                        per-page="10"
                        @click="openCard"
                        :hoverable="true"
                        :data="filteredCompanies"
                        :columns="columns">
                    </b-table>      
                </article>
            </div>
     
        </div>  
        <b-modal
            v-model="modal"
            has-modal-card
            :can-cancel="true">
            <NewEducationPartnerForm @partnerCreated="partnerCreated" @closeModal="modal = false"/>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import NewEducationPartnerForm from '@/views/admin/forms/NewEducationPartner'
export default {
    components: {
            NewEducationPartnerForm,
        },
        data() {
            return {
                search: '',
                companies: [],
                modal: false,
                columns: [
                    {
                        field: 'vat',
                        label: 'Y-tunnus'
                    },
                    {
                        field: 'name',
                        label: this.$t('message.companies.list.name')
                    }
                ]
            }
        },
        computed: {
            filteredCompanies() {
                if(this.search == '') {
                    return this.companies;
                }
                let filtered = [];
                this.companies.forEach((company) => {
                    if(company.name.toLowerCase().includes(this.search.toLowerCase())) {
                        filtered.push(company);
                    }
                    if(company.vat.includes(this.search)) {
                        filtered.push(company);
                    }
                });
                return filtered;
            }
        },
        methods: {
            partnerCreated() {
                this.modal = false;
                this.getCompanies();
            },
            getCompanies() {
                axios.get('/education-partners').then((response) => {
                    this.companies = response.data;
                })
            },
            companyCreated(datax) {
                this.getCompanies();
                this.modal = false;
                console.log(datax);
            },
            openAddCompanyModal() {
                this.modal = true;
            },
            openCard(data) {
                this.goToWithId('AdminEducationPartner', data.id);
            }
        },
        mounted() {
            this.getCompanies();
        }
    }
</script>

